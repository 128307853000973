// import { debounce } from '@mui/material'
import { IconButton, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridFilterModel, GridRenderCellParams, GridToolbarQuickFilter, GridValueGetterParams, ruRU } from '@mui/x-data-grid-premium'
import Button from 'components/ui/Button/Button'
import DataGrid from 'components/ui/DataGrid/DataGrid'
import { RenderFiltersType } from 'components/ui/DataGrid/types'
import FontIcon from 'components/ui/FontIcon'
import { renderHeader } from 'components/utils/datagrid'
import useDebounce from 'hooks/useDebounce'
import { t } from 'i18next'
import { ResponsibleType } from 'models/responsible.model'
import React, { useMemo } from 'react'
import { useGetMeQuery } from 'reducers/api/employee.api'
import { useUpdateResponsibleMutation } from 'reducers/api/responsible.api'
import buildColleagueFinder from '../utils'

interface ResponsibleReferenceTableProps {
  data: ResponsibleType[];
  expansionsInfo: any;
  groupingExpansion: number;
  isEditable: boolean;
  onResponsibleClick: (data: ResponsibleType) => void;
  showResponsibleNode: (data: ResponsibleType) => void;
  updateResponsible: (data: ResponsibleType) => void;
  deleteResponsible: (data: ResponsibleType) => void;
  handleAddResponsible: () => void;
  setQueryOptions: React.Dispatch<any>;
  onSuccess?: () => void;
  changeExpansions: () => void;
}

const OrderControls = (props: GridRenderCellParams<ResponsibleType> & { colleagues: ResponsibleType[], changeOrder: (employee: ResponsibleType, newNumber: number) => void }) => {

  const { colleagues, changeOrder, ...params } = props

  const index = colleagues?.findIndex(colleague => colleague.id === params.row.id)

  const isFirst = index === 0
  const isLast = index === colleagues?.length - 1

  return (
    <Stack direction='row'>
      <IconButton
        size='small'
        onClick={() => changeOrder(params.row, colleagues?.[index - 1].number)} sx={{ padding: '2px' }}
        disabled={isFirst}
      >
        <FontIcon fontSize='small' icon='expand_less' sx={!isFirst ? { color: 'primary.main' } : {}} />
      </IconButton>
      <IconButton
        size='small'
        onClick={() => changeOrder(params.row, colleagues?.[index + 1].number)} sx={{ padding: '2px' }}
        disabled={isLast}
      >
        <FontIcon fontSize='small' icon='expand_more' sx={!isLast ? { color: 'primary.main' } : {}} />
      </IconButton>
    </Stack>
  )
}

const ResponsibleReferenceTable = (props: ResponsibleReferenceTableProps) => {
  const { debounce } = useDebounce()
  const {
    data = [],
    expansionsInfo,
    groupingExpansion,
    isEditable,
    handleAddResponsible,
    onResponsibleClick,
    showResponsibleNode,
    updateResponsible,
    deleteResponsible,
    setQueryOptions,
    changeExpansions,
    onSuccess,
  } = props;

  const { data: meData } = useGetMeQuery()

  const [patchResponsible] = useUpdateResponsibleMutation()

  const colleagueFinder = useMemo(() => buildColleagueFinder(data), [data])

  const changeOrder = async (employee: ResponsibleType, newNumber: number) => {
    const res = await patchResponsible({ id: employee.id, number: newNumber })
    if (!(res as any).error) {
      onSuccess()
    }
  }

  const referenceColumns: GridColDef<ResponsibleType>[] = useMemo(
    () =>
      [
        {
          field: 'subdivision',
          headerName: t('kbl.dataGrid.subdivision'),
          flex: 1,
          valueGetter: ({ row }: GridValueGetterParams<ResponsibleType>) =>
            row.subdivision?.name,
          groupingValueGetter: ({
            row,
          }: GridValueGetterParams<ResponsibleType>) => row.subdivision?.name,
          sortable: false,
        },
        {
          field: 'department',
          headerName: t('kbl.dataGrid.department'),
          flex: 1,
          sortable: false,
          valueGetter: ({ row }: GridValueGetterParams<ResponsibleType>) =>
            row.department?.name,
          groupingValueGetter: ({
            row,
          }: GridValueGetterParams<ResponsibleType>) => row.department?.name,
        },
        {
          field: 'number',
          headerName: '№',
          flex: 1,
          sortable: false,
        },
        ...(isEditable
          ? [
              {
                field: '__order__',
                width: 64,
                minWidth: 64,
                maxWidth: 64,
                sortable: false,
                headerClassName: 'hideRightSeparator',
                flex: 0,
                renderCell: (params: GridRenderCellParams<ResponsibleType>) => {
                  return params.rowNode?.type !== 'group' ? (
                    <OrderControls
                      {...params}
                      colleagues={colleagueFinder.getColleagues(params.row.id)}
                      changeOrder={changeOrder}
                    />
                  ) : null;
                },
              },
            ]
          : []),
        {
          field: 'fullName',
          headerName: t('kbl.dataGrid.fullName'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'post',
          headerName: t('kbl.dataGrid.post'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'address',
          headerName: t('kbl.dataGrid.address'),
          flex: 1.5,
          sortable: false,
        },
        {
          field: 'functional',
          headerName: t('kbl.dataGrid.functional'),
          flex: 1.5,
          sortable: false,
        },
        {
          field: 'email',
          headerName: t('kbl.dataGrid.email'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'phone',
          headerName: t('kbl.dataGrid.phone'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'delegation',
          headerName: t('kbl.dataGrid.delegatingFunctions'),
          headerClassName: 'hideRightSeparator',
          flex: 1.9,
          sortable: false,
        },
        {
          field: 'actions',
          type: 'actions',
          align: 'center',
          getActions: (params) =>
            params.row.id
              ? [
                  <GridActionsCellItem
                    icon={<FontIcon icon='person' />}
                    label={t('kbl.buttons.showResponsibility')}
                    onClick={() => showResponsibleNode(params.row)}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    icon={<FontIcon icon='edit' />}
                    label={t('kbl.buttons.edit')}
                    onClick={() => {
                      updateResponsible(params.row);
                    }}
                    disabled={!isEditable}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    icon={
                      <FontIcon
                        icon='delete'
                        sx={{ color: 'primary.mosmetro' }}
                      />
                    }
                    label={t('kbl.buttons.deleteThisResponsible')}
                    onClick={() => deleteResponsible(params.row)}
                    disabled={!isEditable}
                    showInMenu
                  />,
                ]
              : [],
        },
      ].map((column: any) => ({ renderHeader, ...column })),
    [data, colleagueFinder]
  );

  const renderFilters: RenderFiltersType = (apiRef) => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  const handleFilterModelChange = (model: GridFilterModel) => {
    const searchValue = model.quickFilterValues[0]
    const options = searchValue
      ? [
        {
          logicalOperator: 'or',
          field: 'firstName',
          operator: 'like',
          value: searchValue,
        },
        {
          logicalOperator: 'or',
          field: 'lastName',
          operator: 'like',
          value: searchValue,
        },
        {
          logicalOperator: 'or',
          field: 'post',
          operator: 'like',
          value: searchValue,
        },
        {
          logicalOperator: 'or',
          field: 'email',
          operator: 'like',
          value: searchValue,
        },
      ]
      : []

    model.items.forEach(item => {
      if (item.value)
        options.push({
          logicalOperator: 'and',
          field: item.field,
          operator: 'like',
          value: item.value
        })
    })

    debounce(() => setQueryOptions(options))
  }

  return (
    <Box overflow='auto' sx={{ width: '100%' }}>
      <Button size='small' onClick={changeExpansions}>
        {expansionsInfo}
      </Button>
      <DataGrid
        stateKey='responsible'
        stateStorage='localStorage'
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        rows={data || []}
        columns={referenceColumns}
        sx={{ '--DataGrid-cellOffsetMultiplier': 4 }}
        groupingColDef={{
          headerName: '',
          width: 30,
          minWidth: 30,
          maxWidth: 30,
          flex: 0,
          headerClassName: 'hideRightSeparator',
          hideDescendantCount: true,
        }}
        defaultGroupingExpansionDepth={groupingExpansion}
        showDetailOnRowClick
        rowGroupingModel={['subdivision', 'department']}
        initialState={{
          columns: {
            columnVisibilityModel: {
              subdivision: false,
              __check__: false,
              department: false,
              number: false,
            },
          },
          sorting: {
            sortModel: [
              { field: '__row_group_by_columns_group__', sort: 'asc' },
              { field: 'subdivision', sort: 'asc' },
              { field: 'department', sort: 'asc' },
              { field: 'number', sort: 'asc' },
            ],
          },
        }}
        disableColumnFilter
        hideFooter
        disableColumnReorder
        disableColumnMenu
        disableRowSelectionOnClick
        filterMode='server'
        loading={!data}
        slotProps={{
          toolbar: {
            ...(isEditable ? { onAdd: handleAddResponsible } : undefined),
            renderFilters,
          },
        }}
        onFilterModelChange={handleFilterModelChange}
        onRowDoubleClick={({ row }) => row.id && onResponsibleClick(row)}
      />
    </Box>
  );
}

export default ResponsibleReferenceTable
