import { Box, ClickAwayListener, IconButton, MenuItem, Menu as MuiMenu, MenuProps as MuiMenuProps, Stack } from "@mui/material"
import { NOOP } from "config/constants"
import { useAppDispatch, useAppSelector } from "config/store"
import { setContextId } from "reducers/context"
import FontIcon from "../FontIcon"
import MenuList from "../MenuList"
import { MenuItemType } from "../MenuList/MenuList"

interface MenuProps extends MuiMenuProps {
  header?: MenuItemType[]
  items: MenuItemType[]
}

function Menu(props: MenuProps) {

  const dispatch = useAppDispatch()
  const currentContextId = useAppSelector(state => state.context.currentContextId)

  const { header, items, onClose = NOOP, sx, ...rest } = props

  const handleClickAway = (e: MouseEvent) => {
    if (currentContextId)
      dispatch(setContextId(undefined))
    onClose(e, 'backdropClick')
  }

  const handleHeaderClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, command: Function = NOOP) => {
    e.preventDefault()
    e.stopPropagation()
    command()
    onClose(undefined, 'backdropClick')
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <MuiMenu
          hideBackdrop
          MenuListProps={{ dense: true }}
          transitionDuration={0}
          onClose={onClose}
          sx={{
            width: 'fit-content',
            ...sx,
          }}
          {...rest}
        >
          {Boolean(header)
            ? (
              <MenuItem disableRipple={true}
                sx={{
                  padding: '0 8px 8px 8px',
                  mb: 1,
                  borderBottom: '1px solid var(--mui-palette-primary-line)',
                  ':hover': {
                    bgcolor: 'white',
                    cursor: 'default'
                  },
                  ':focus': {
                    bgcolor: 'white',
                    cursor: 'default'
                  }
                }}
              >
                <Stack direction='row' sx={{ width: '100%' }}>
                  {header.map(({ icon, disabled, expander, data, command }, idx) => {
                    return expander
                      ? <Box key={idx} sx={{ display: 'flex', flex: 1 }}></Box>
                      : (
                        <IconButton key={idx} disabled={disabled} onClick={(e) => handleHeaderClick(e, command)}>
                          <FontIcon icon={icon} color={disabled ? 'disabled' : data?.color || 'primary'} sx={data?.sx} />
                        </IconButton>
                      )
                  })}
                </Stack>
              </MenuItem>
            )
            : <MenuItem sx={{ bgcolor: 'white', minHeight: 0, padding: 0 }} /> // без этого хака меню открывается без тени 
          }
          <MenuList items={items} onClose={onClose as any} />
        </MuiMenu>
      </Box>
    </ClickAwayListener>
  )
}

export default Menu
