import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { EmployeeManyType, EmployeeType } from "models/employee.model"
import { RoleManyType } from "models/role.model"
import { UserManyType, UserType } from "../../models/user.model"
import { extraNames } from "./utils"

const apiUrl = `${API_URL}/users`

export const users = commonApi.injectEndpoints({
  endpoints: builder => ({

    getUsers: builder.query<UserManyType, void>({
      query: () => ({
        url: `${apiUrl}/employee/?size=100`,
      }),
      transformResponse: (result: EmployeeManyType) => ({
        ...result, content: result.content.map((item: EmployeeType) => ({
          ...item,
          ...extraNames(item)
        }))
      }),
      providesTags: ['USERS'],
    }),

    getFilteredUsersEmployee: builder.mutation<EmployeeManyType, any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/employee/filtering?size=500`,
        body,
      }),
      transformResponse: (result: EmployeeManyType) => ({
        ...result, content: result.content.map((item: EmployeeType) => ({
          ...item,
          ...extraNames(item)
        }))
      }),
      invalidatesTags: ['EMPLOYEES']
    }),

    createUser: builder.mutation<any, UserType>({
      query: (UserType) => ({
        method: 'POST',
        url: `${apiUrl}/user/create`,
        body: UserType,
      }),
      //invalidatesTags: ['USERS']
    }),

    updateUser: builder.mutation<any, UserType>({
      query: (body) => ({
        method: 'PUT',
        url: `${apiUrl}/user/edit/${body.id}`,
        body: body,
      }),
      //invalidatesTags: ['USERS']
    }),

    removeUser: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `${apiUrl}/user/${id}`,
      }),
      invalidatesTags: ['USERS']
    }),

    getRoles: builder.query<RoleManyType, void>({
      query: () => ({
        url: `${API_URL}/kbl/role-kbl`,
      }),
    }),
  })
})

export const {
  useUpdateUserMutation,
  useGetFilteredUsersEmployeeMutation,
  useGetRolesQuery,
  useCreateUserMutation,
  useRemoveUserMutation
} = users
