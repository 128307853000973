import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import FontIcon from "components/ui/FontIcon"
import { ResponsibleType } from "models/responsible.model"

interface ItemProps {
  icon?: string
  text: string
}

const Item = ({ icon, text }: ItemProps) => (
  <ListItem sx={{ height: '32px' }}>
    <ListItemIcon sx={{ minWidth: '20px', mr: '8px' }}>
      {icon && <FontIcon icon={icon} />}
    </ListItemIcon>
    <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
      {text}
    </ListItemText>
  </ListItem>
)

const ResponsibleItemContent = (props: ResponsibleType) => {

  const { fullName, department, post, phone, address, node } = props

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(17, 35, 48, 0.05)',
        marginBlock: '0 8px'
      }}
    >
      <List dense>
        <Item icon='person' text={fullName} />
        <Item icon='work' text={department?.name} />
        <Item text={post} />
        <Item icon='apartment' text={address} />
        <Item icon='call' text={phone} />
      </List>
    </Box>
  )
}

export default ResponsibleItemContent
