import { Box } from '@mui/material'
import List from '@mui/material/List'
import FontIcon from 'components/ui/FontIcon'
import TreeItem from 'components/ui/Tree/TreeItem'
// import { NOOP } from 'config/constants'
import NodeType from 'models/node.model'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useGetFavoritesQuery, 
	// useGetHomepageQuery, 
	useGetTrendsQuery } from 'reducers/api/employee.api'
import SidebarTreeItem from './SidebarTreeItem'
import TreeExpand from './TreeExpand'
import { isShowQuickNodes } from 'config/constants'

export default function Tree() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const fullPath = decodeURI(location.pathname.slice(1));

  const [section, nodeId, ...restPath] = fullPath.split('/');

  const { data: trendsData } = useGetTrendsQuery();
  // const { data: homepageData } = useGetHomepageQuery();
  const { data: favoritesData } = useGetFavoritesQuery();

  const [data, setData] = useState<NodeType[]>([]);
  const [expanded, setExpanded] = useState([]);

  const handleExpand = (id: string) => {
    const expandedSet = new Set(expanded);
    if (expandedSet.has(id)) expandedSet.delete(id);
    else expandedSet.add(id);
    setExpanded(Array.from(expandedSet));
  };

  const handleClick = (item: NodeType) => {
    const selected = section === 'content' && item.id === nodeId;
    if (!expanded.includes(item.id) || selected) handleExpand(item.id);
    if (item.isDirectory) {
      navigate(`/reference/${item.id}`);
    } else {
      navigate(`/content/${item.id}`);
    }
  };

  const setItem = (item: NodeType) => {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          overflow: 'auto',
          alignItems: 'center',
          gap: '0.2rem',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {item.name}
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', paddingBottom: '2px' }}
        >
          {/* {item.id === homepageData?.id && (
            <FontIcon icon='home' sx={{ fontSize: '10px', opacity: '0.5' }} />
          )} */}
          {favoritesData?.find((x) => x.id === item.id) && (
            <FontIcon icon='star' sx={{ fontSize: '10px', opacity: '0.5' }} />
          )}
          {(isShowQuickNodes && item.isQuick) && (
            <FontIcon icon='bolt' sx={{ fontSize: '10px', opacity: '0.5' }} />
          )}
        </Box>
      </Box>
    );
  };

  const setSx = (item: NodeType) => {
			if (!isShowQuickNodes) return
        return {
          ...(item.isQuick && {
            '& .MuiListItemText-root': {
              '& .MuiTypography-root': {
                fontWeight: 800,
              },
            },
          }),
        };
  };

  const buildTree = useCallback(
    () =>
      data?.map((item) => (
        <SidebarTreeItem
          key={item.id}
          node={item}
          item={setItem(item)}
          icon={
            <FontIcon
              icon='book_2'
              sx={{
                padding: '6px',
                borderRadius: '4px',
                backgroundColor: 'primary.main',
                color: 'white',
              }}
            />
          }
          //icon={<NodeIcon node={item} width={30} />}
          expanded={expanded.includes(item.id)}
          selected={section === 'content' && item.id === nodeId}
          selectedSubItemId={section === 'content' ? nodeId : undefined}
          showActions='always'
          onExpand={handleExpand}
          onClick={handleClick}
          setItem={setItem}
          setSx={setSx}
        />
      )),
    [data, location, expanded, 
					// homepageData, 
					favoritesData]
  );

  useEffect(() => {
    if (trendsData /*  && section === 'content' */) {
      setData(
        trendsData?.map((x, idxX) => ({
          ...x,
          order: idxX,
          children: x.children?.map((y, idxY) => ({ ...y, order: idxY })),
        }))
      );

      const expandedSet = new Set(expanded);
      expandedSet.add(nodeId);
      trendsData?.forEach((root) =>
        root.children?.forEach((trend) => {
          if (trend.id === nodeId) {
            expandedSet.add(trend.parent.id);
          }
        })
      );
      setExpanded(Array.from(expandedSet));
    }
  }, [trendsData]);
	
		const toggleExpand = ()=>{
			setExpanded(pre => pre.length === data.length ? [] : data.map((el) => el.id))
		}

  return (
    <List
      key={location.pathname}
      component='nav'
      aria-labelledby='nested-list-subheader'
      sx={{
        paddingLeft: '10px',
      }}
    >
      <TreeExpand toggleExpand={toggleExpand} data={data} expanded={expanded} />
      {/* Отключение 'Домашняя' */}
      {/* <TreeItem
        key={'homepage'}
        node={{ id: 'homepage', name: 'Домашняя' }}
        icon={
          <FontIcon
            icon='house'
            sx={{
													display: 'none',
              padding: '6px',
              borderRadius: '4px',
              backgroundColor: 'primary.main',
              color: 'white',
            }}
          />
        }
        selected={section === 'homepage'}
        onClick={
          homepageData?.id
            ? () => {
                navigate(`/content/${homepageData?.id}`);
              }
            : NOOP
        }
      /> */}
      <TreeItem
        key={'favorites'}
        node={{ id: 'favorites', name: t('kbl.title.favorites') }}
        icon={
          <FontIcon
            icon='star'
            sx={{
              padding: '6px',
              borderRadius: '4px',
              backgroundColor: 'primary.main',
              color: 'white',
            }}
          />
        }
        selected={section === 'favorites'}
        onClick={() => {
          navigate(`/favorites`);
        }}
      />
      {buildTree()}
      {/* <TreeItem
        key={'recycle'}
        node={{ id: 'recycle', name: t('kbl.title.recycle') }}
        icon={<FontIcon icon='inventory_2' sx={{ padding: '6px', borderRadius: '4px', backgroundColor: 'primary.main', color: 'white' }} />}
        selected={section === 'recycle'}
        onClick={() => { navigate(`/recycle`) }}
      /> */}
    </List>
  );
}
