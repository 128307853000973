import { useAppSelector } from "config/store"
import { useMemo } from "react"
import { isEditable } from "utils/checkRight"

const useSelected = () => {
  const clipboard = useAppSelector((state) => state.clipboard)
  const selected = clipboard?.selected

  const selectedOne = useMemo(() => selected?.length === 1 ? selected?.[0] : null, [selected])

  const hasNonEditable = useMemo(() =>
    selected?.filter((item) => !isEditable(item)).length > 0, [selected, isEditable])

  const hasDeleted = useMemo(() => selected?.filter((item) => item.isDeleted).length > 0, [selected])

  const hasDeletedOnly = useMemo(() =>
    selected?.filter((item) => item.isDeleted).length === selected?.length, [selected])

  const hasTrends = useMemo(() =>
    selected?.filter(
      (item) => item.nodeType === 'ROOT' || item.nodeType === 'TREND'
    )?.length > 0, [selected])

  const isDownloadAllowed = useMemo(() => selectedOne
    ? selectedOne.nodeType === 'DOCUMENT' && selectedOne.loadStatus === 'READY'
    : false, [selectedOne])

  const isArchiveAllowed = useMemo(() =>
    selected && !hasTrends && !hasNonEditable && !hasDeleted, [selected, hasTrends, hasNonEditable, hasDeleted])

  const isRestoreAllowed = useMemo(() =>
    selected && !hasTrends && !hasNonEditable && hasDeleted, [selected, hasTrends, hasNonEditable, hasDeleted])

  const isClipboardData = useMemo(() =>
    ['cut', 'copy'].includes(clipboard?.op) && clipboard?.data, [clipboard])

  const isCopyCutAllowed = useMemo(() => selected && !hasTrends && !hasNonEditable, [selected, hasTrends, hasNonEditable])

  return { selected, selectedOne, isArchiveAllowed, isCopyCutAllowed, isClipboardData, isDownloadAllowed, hasDeletedOnly, isRestoreAllowed }

}

export default useSelected
