import { useAppSelector } from "config/store"
import NodeType from "models/node.model"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { EntitiesSortDirection, EntitiesSortMode, setSortDirection, setSortMode } from "reducers/toolbar"

export const useSort = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const sortMode = useAppSelector(state => state.toolbar.sortMode)
  const sortDirection = useAppSelector(state => state.toolbar.sortDirection)

  const handleSortModeChange = (mode: EntitiesSortMode) => {
    dispatch(setSortMode({ sortMode: mode }))
  }

  const handleSortDirectionChange = (direction: EntitiesSortDirection) => {
    dispatch(setSortDirection({ sortDirection: direction }))
  }

  const sortNodes = (nodes: NodeType[] = [], mode: EntitiesSortMode = sortMode) => {
    const _nodes = [...nodes]
    return _nodes?.sort((a, b) => {
      if (a[mode] > b[mode])
        return sortDirection === EntitiesSortDirection.ASC ? 1 : -1
      else
        return sortDirection === EntitiesSortDirection.ASC ? -1 : 1
    })
  }

  const sortOptions = useMemo(() => [
    { key: 1, label: t(`kbl.sort.${EntitiesSortMode.ORDER}`), selected: sortMode === EntitiesSortMode.ORDER, command: () => handleSortModeChange(EntitiesSortMode.ORDER) },
    { key: 2, label: t(`kbl.sort.${EntitiesSortMode.NAME}`), selected: sortMode === EntitiesSortMode.NAME, command: () => handleSortModeChange(EntitiesSortMode.NAME) },
    { key: 3, label: t(`kbl.sort.${EntitiesSortMode.TYPE}`), selected: sortMode === EntitiesSortMode.TYPE, command: () => handleSortModeChange(EntitiesSortMode.TYPE) },
    { key: 4, label: t(`kbl.sort.${EntitiesSortMode.DATE}`), selected: sortMode === EntitiesSortMode.DATE, command: () => handleSortModeChange(EntitiesSortMode.DATE) },
    { key: 5, label: t(`kbl.sort.${EntitiesSortMode.AUTHOR}`), selected: sortMode === EntitiesSortMode.AUTHOR, command: () => handleSortModeChange(EntitiesSortMode.AUTHOR) },
    { key: 6, divider: true },
    { key: 7, label: '↑ По возрастанию', selected: sortDirection === EntitiesSortDirection.ASC, command: () => handleSortDirectionChange(EntitiesSortDirection.ASC) },
    { key: 8, label: '↓ По убыванию', selected: sortDirection === EntitiesSortDirection.DESC, command: () => handleSortDirectionChange(EntitiesSortDirection.DESC) },
  ], [sortMode, sortDirection])

  return { sortOptions, sortMode, sortDirection, sortNodes }

}
