export const openFile = (contentType: string, blob: Blob, downloadFilename?: string) => {
  const blob_body = new Blob([blob], { type: contentType });

  const link = document.createElement('a');
  link.target = '_blank';

  // если есть downloadFilename - файл будет скачиваться, а не открываться в окне
  if (downloadFilename) link.download = downloadFilename;

  link.href = URL.createObjectURL(blob_body);

  link.click();
  URL.revokeObjectURL(link.href);
};
