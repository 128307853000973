import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ACCESS_TOKEN, REFRESH_TOKEN, ROLE } from 'config/constants'

export interface AuthState {
  logged: boolean | undefined,
  expiresIn: undefined,
		userRole: string
}

const initialState: AuthState = {
  logged: undefined,
  expiresIn: undefined,
  userRole: undefined,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<any>) => {
      sessionStorage.setItem(ACCESS_TOKEN, action.payload.accessToken);
      localStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken);
      state.logged = true;
      state.expiresIn = action.payload.expiresIn;
					},
					logout: (state: AuthState) => {
						sessionStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
						localStorage.removeItem(ROLE);
      state.logged = false;
      state.expiresIn = undefined;
						state.userRole = undefined;
					},
					settingRole: (state: AuthState, action: PayloadAction<any>) => {
						localStorage.setItem(ROLE, action.payload);
      state.userRole = action.payload;
    },
  },
});

export const { login, logout, settingRole } = auth.actions;

export default auth
