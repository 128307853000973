import { useCallback, useEffect } from 'react'

interface IUseKeysPressProps {
  onKeyCopy?: () => void
  onKeyCut?: () => void
  onKeyPaste?: () => void
  onEscape?: () => void
  onDelete?: () => void
  onOpen?: () => void
  handleAccept?: () => void
  handleReject?: () => void
  open?: boolean
}

const useKeysPress = (props: IUseKeysPressProps) => {
  const handleKeyPress = useCallback(
    (event) => {
      const {
        onKeyCopy,
        onKeyCut,
        onKeyPaste,
        onEscape,
        onDelete,
        onOpen: openNode,
        handleAccept,
        handleReject,
        open,
      } = props

      if (event.key === 'Escape' && onEscape) onEscape()

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === 'c' || event.key === 'с') &&
        onKeyCopy
      ) {onKeyCopy();}

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === 'x' || event.key === 'ч') &&
        onKeyCut
      )
        onKeyCut();

      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === 'v' || event.key === 'м') &&
        onKeyPaste
      )
        onKeyPaste();

      if (
        (event.key === 'Backspace' || event.key === 'Delete') &&
        onDelete
      )
        onDelete()

      // Открытие Node
      if (event.key === 'Enter' && openNode && !open) openNode()
      // Подтверждение удаления и отмены в ConfirmDialog
      if (event.key === 'Enter' && open && handleAccept) handleAccept()
      if (event.key === 'Escape' && open && handleReject) handleReject()
    },
    [props]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
}

export default useKeysPress
