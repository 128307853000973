import { Box, Stack } from "@mui/material"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import ChainStack from "components/ui/ChainStack/ChainStack"
import { useAppSelector } from "config/store"
import useClipboard from "hooks/useClipboard"
import NodeType from "models/node.model"
import { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable, OnDragEndResponder, OnDragStartResponder } from "react-beautiful-dnd"
import { OnSelectType } from "view/types/node"
import Node from "../Node"
import NodeName from "../Node/NodeName/NodeName"

interface EntitiesGridProps {
  parent: NodeType
  nodes: NodeType[]
  selected?: NodeType[]
  onSelect: OnSelectType
  onPaste?: (destination: NodeType) => Promise<void>
}

const EntitiesGrid = (props: EntitiesGridProps) => {

  const { parent, nodes, onSelect, onPaste } = props

  const selected = useAppSelector(state => state.clipboard?.selected)

  const { clipboard, cut } = useClipboard(parent)

  const [isDragStarted, setIsDragStarted] = useState(false)
  const [destination, setDestination] = useState<NodeType>()

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    display: 'flex',
    alignItems: 'center',
    ...draggableStyle,
    transform: !isDragging && 'none !important',
    userSelect: 'none',
    // change background colour if dragging
    ...(isDragging && {
      //background: 'var(--mui-palette-primary-main01)',
    })
    // styles we need to apply on draggables
  })

  const handleDragStart: OnDragStartResponder = ({ draggableId }) => {
    setIsDragStarted(true)
    const source = nodes?.find(x => x.id === draggableId)
    const isWithinSelected = selected?.find(x => x.id === source?.id)
    if (!isWithinSelected) onSelect([source])
  }

  const handleDragEnd: OnDragEndResponder = ({ destination }) => {
    setIsDragStarted(false)
    const { droppableId } = destination ?? {}
    if (!droppableId) return
    const destinationNode = nodes?.find(x => x.id === droppableId)
    setDestination(destinationNode)
    cut(selected)
  }

  useEffect(() => {
    if (clipboard.op === 'cut' && destination)
      onPaste(destination)
  }, [clipboard.op, destination])

  return (
    <Box
      component='ul'
      sx={{
        display: 'flex',
        overflow: 'auto',
        flexWrap: 'wrap',
        padding: '20px 1px 20px 1px',
        gap: '10px',
        listStyle: 'none',
        margin: 0,
      }}
    >
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        {nodes?.map((item: NodeType, idx: number) => {
          const isSelected = Boolean(selected?.find(x => x.id === item.id))
          const isDropDisabled = item.nodeType !== 'FOLDER' || isSelected
          return (
            <Droppable key={item.id} droppableId={item.id} isDropDisabled={isDropDisabled}>
              {(providedDroppable, snapshotDroppable) => {



                return (
                  <Box
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    <Box maxHeight='0px'>{providedDroppable.placeholder}</Box>
                    <Draggable draggableId={item.id} index={idx}>
                      {(providedDraggable, snapshotDraggable) => {
                        return (
                          <Box
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            sx={{
                              ...getItemStyle(
                                snapshotDraggable.isDragging,
                                providedDraggable.draggableProps.style
                              ),
                              ...(snapshotDroppable.isDraggingOver && {
                                outline: '1px dashed rgba(0, 0, 255, 1)',
                                opacity: '0.5',
                              })
                            }}
                          >
                            {snapshotDraggable.isDragging ? (
                              <Stack width='200px'>
                                {selected?.map(x => (
                                  <ChainStack key={x.id} spacing='4px' padding='8px'>
                                    <NodeIcon node={x} small />
                                    <NodeName name={x.name} sx={{ textAlign: 'left' }} />
                                  </ChainStack>
                                ))}
                              </Stack>
                            )
                              : (
                                <Box
                                  sx={{ ...(isSelected && isDragStarted && { visibility: 'hidden' }) }}
                                >
                                  <Node
                                    component='li'
                                    node={item}
                                    selected={isSelected}
                                    onSelect={onSelect}
                                  />
                                </Box>
                              )
                            }
                          </Box>
                        )
                      }}
                    </Draggable>
                  </Box>
                )
              }}
            </Droppable>
          )
        })}
      </DragDropContext>
    </Box>
  )
}

export default EntitiesGrid
