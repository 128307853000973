import { Stack } from "@mui/material"
import ResponsibleAutoComplete from "components/controls/ResponsibleAutoComplete/ResponsibleAutoComplete"
import { AnswerType, QuizAnswersType } from "models/quiz.model"
import { ResponsibleType } from "models/responsible.model"
import { useEffect, useState } from "react"
import { useGetQuizQuery } from "reducers/api/quiz.api"
import { QuestionsGroup } from "./Questions"
import { QuizProps } from "./types"

const Quiz = ({ quizType, onChange }: QuizProps) => {

  const { data: quizData } = useGetQuizQuery({ quizType })

  const quiz = quizData?.content[0]

  const [responsible, setResponsible] = useState<ResponsibleType | string>()
  const [answers, setAnswers] = useState<AnswerType>()

  const handleChange = (id: string, value: AnswerType) => { setAnswers(prev => ({ ...prev, [id]: value })) }

  useEffect(() => {
    let _answers: any = {}
    quizData?.content[0].questions.forEach(group => {
      group.questions.forEach(question => {
        if (!_answers[question.id])
          _answers[question.id] = { ...question, answer: question.answerType === 'SCORE' ? '0' : '-' }
      })
    })
    setAnswers(_answers)
  }, [quizData])

  useEffect(() => {
    const data: QuizAnswersType = {
      quizDirectory: {
        id: quiz?.id,
        version: quiz?.version,
      },
      assessed: (responsible as ResponsibleType)?.fullName || responsible as string,
      answers: answers ? Object.values(answers) : []
    }
    onChange(data)
  }, [quiz, responsible, answers])

  return (
    <Stack spacing='22px'>
      <ResponsibleAutoComplete
        departments={quizType === 'SUBDIVISION'}
        renderInputProps={{
          label: quizType === 'WORKER' ? 'Сотрудник' : 'Подразделение',
        }}
        onChange={(_, value: ResponsibleType | string) => setResponsible(value)}
      />
      <Stack spacing='22px' height='100%' flex='1' justifyContent='flex-start'>
        {quiz?.questions.map(group => {
          return <QuestionsGroup key={group.group} {...group} onAnswer={handleChange} />
        })}
      </Stack>
    </Stack>
  )
}

export default Quiz
