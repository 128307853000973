import { GridCellParams, GridToolbarQuickFilter, GridValueGetterParams } from "@mui/x-data-grid-premium"
import { renderCell, renderHeader } from "components/utils/datagrid"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import DataGrid from "../../../../components/ui/DataGrid/DataGrid"
import { RenderFiltersType } from "../../../../components/ui/DataGrid/types"
import { UserType } from "../../../../models/user.model"
import ItemName from "../../AccessGroups/components/ItemName/ItemName"
import UserAction from "./UserAction/UserAction"

interface UsersListProps {
  usersList: UserType[]
  isLoading: boolean
  onRemove: (user: UserType) => Promise<void>
  onEdit: (value: UserType) => void
}

const UsersList = ({ usersList, isLoading, onRemove, onEdit }: UsersListProps) => {
  const [pending, setPending] = useState<string[]>(null)
  const { t } = useTranslation()

  const handleRemove = async (data: UserType) => {
    setPending(v => [...(v || []), data.id])
    await onRemove(data)
  }

  const columns = useMemo(() => [
    {
      headerName: t('kbl.dataGrid.name'), field: 'fullName', flex: 0.7,
      renderCell: (cell: GridCellParams) => (
        <ItemName name={cell.row.fullName} pending={pending?.includes(cell.row.id)} />
      )
    },
    {
      headerName: t('kbl.dataGrid.role'), field: 'role', flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.role?.name || '-'
      },
      renderCell: ({ row, value }: GridCellParams) => (
        <ItemName name={value as string} pending={pending?.includes(row.id)} />
      )
    },
    {
      field: 'actions', type: 'actions', width: 90,
      renderCell: (cell: GridCellParams) => (
        <UserAction
          isPending={pending?.includes(cell.row.id)}
          onEdit={() => onEdit(cell.row)}
          onDelete={() => handleRemove(cell.row)}
        />
      )
    }
  ].map((column: any) => ({ renderCell, renderHeader, ...column })), [pending])

  const renderFilters: RenderFiltersType = () => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  return (
    <DataGrid
      columns={columns}
      rows={usersList || []}
      stateKey='users'
      stateStorage='localStorage'
      density='compact'
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      autoPageSize
      loading={isLoading}
      sortingOrder={['asc', 'desc']}
      slotProps={{
        toolbar: {
          disableExport: true,
          renderFilters
        },
      }}
    />
  )
}

export default UsersList
