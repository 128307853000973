import Button from 'components/ui/Button/Button'
import { CurrentColleaguesType, IDepartment, ISubdivision, ResponsibleType } from 'models/responsible.model'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../../components/ui/Dialog'
import {
  useCreateResponsibleMutation,
  useUpdateResponsibleMutation,
} from '../../../../../reducers/api/responsible.api'
import ResponsibleForm from './ResponsibleForm/ResponsibleForm'
export interface IGroupsData {
  subdivisions: ISubdivision[]
  orphanDepartments: IDepartment[]
}

interface ResponsibleDialogProps {
  responsible?: ResponsibleType
  currentColleaguesList?: CurrentColleaguesType[]
  onClose: () => void
  onSuccess: () => void
  groupsData?: IGroupsData
}

type FormState = Omit<ResponsibleType, 'department' | 'subdivision'> & {
  department?: {}
  subdivision?: {}
}

const ResponsibleDialog = ({
  responsible,
  currentColleaguesList,
  onClose,
  onSuccess,
  groupsData,
}: ResponsibleDialogProps) => {
  const { t } = useTranslation()
  const [currentSubdivision, setCurrentSubdivision] = useState<string>()
  const [currentDepartment, setCurrentDepartment] = useState<string>()
  const methods = useForm<FormState>()

  const {
    handleSubmit,
    reset,
    formState: { isValid: isFormValid },
  } = methods

  const [updateResponsible] = useUpdateResponsibleMutation()
  const [createResponsible] = useCreateResponsibleMutation()

  useEffect(() => {
    if (responsible)
      setCurrentSubdivision(responsible?.subdivision?.id)
    setCurrentDepartment(responsible?.department?.id)
  }, [responsible])

  useEffect(() => {
    if (!responsible) return
    const defaultValues: FormState = {
      id: responsible.id,
      firstName: responsible.firstName,
      middleName: responsible.middleName,
      lastName: responsible.lastName,
      number: responsible.number,
      department: responsible.department?.id,
      post: responsible.post,
      address: responsible.address,
      phone: responsible.phone,
      email: responsible.email,
      subdivision: responsible.subdivision?.id,
      functional: responsible.functional,
      delegation: responsible.delegation,
    }
    reset(defaultValues)
  }, [responsible])

  const handleFormSubmit = handleSubmit(async (eData: FormState) => {
    const data: FormState = {
      ...eData,
      subdivision: eData.subdivision,
      department: eData.department,
    }
    if (responsible?.id) {
      // проверка на изменение полей
      for (const key in data) {
        (data[key] === responsible[key] ||
          data[key] === undefined ||
          data[key] === responsible?.subdivision?.id ||
          data[key] === responsible?.department?.id) &&
          key !== 'id' &&
          delete data[key]
      }

      if (Object.keys(data).length <= 1) return onClose()
      try {
        const response = await updateResponsible({
          ...data,
        })

        if ((response as any).error) {
          throw (response as any).error
        }
        onSuccess()
        onClose()
      } catch (e) {
        console.log('update responsible error:', e)
      }
    }
    else {
      try {
        const response = await createResponsible({
          ...data,
          subdivision: !eData.subdivision ? null : { id: `${eData.subdivision}` },
          department: !eData.department ? null : { id: `${eData.department}` },
        })
        if ((response as any).error) {
          throw (response as any).error
        }
        onSuccess()
        onClose()
      } catch (e) {
        console.log('create responsible error:', e)
      }
    }
  })

  return (
    <Dialog
      fullHeight
      open={true}
      title={
        responsible?.id
          ? t('kbl.dialog.title.general.changeResponsibleInfo')
          : t('kbl.dialog.title.general.createResponsible')
      }
      onClose={onClose}
      actions={
        <>
          <Button fullWidth variant='outlined' onClick={onClose}>
            {t('kbl.buttons.cancel')}
          </Button>
          <Button
            form='my-form'
            type='submit'
            fullWidth
            variant='contained'
            disabled={!isFormValid}
            color='success'
            onClick={handleFormSubmit}
          >
            {t('kbl.buttons.save')}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <ResponsibleForm
          responsible={responsible}
          currentColleaguesList={currentColleaguesList}
          groupsData={groupsData}
          currentSubdivision={currentSubdivision}
          currentDepartment={currentDepartment}
        />
      </FormProvider>
    </Dialog>
  )
}

export default ResponsibleDialog
