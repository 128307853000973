import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { ISubdivision } from "models/responsible.model"

const apiUrl = `${API_URL}/kbl/subdivision`

export const subdivision = commonApi.injectEndpoints({
  endpoints: builder => ({

    getSubdivisions: builder.query<{ orphanDepartments: ISubdivision[], subdivisions: ISubdivision[] }, void>({
      query: () => ({
        url: `${apiUrl}/subdivision-department-list`,
      }),
    }),

  })
})

export const {
  useGetSubdivisionsQuery,
} = subdivision

export default subdivision
