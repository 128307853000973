import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { GridGroupNode, ruRU } from '@mui/x-data-grid'
import { DataGridPremium, GridEventListener, GridInitialState, useGridApiRef } from '@mui/x-data-grid-premium'
import FontIcon from 'components/ui/FontIcon'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import DataGridToolbar from './DataGridToolbar/DataGridToolbar'
import { CustomDataGridProps } from './types'

const noRowsOverlay = () => (
  <Typography
    variant='subtitle2'
    sx={{
      color: 'primary.text03',
      paddingTop: 2,
      textAlign: 'center'
    }}
  >
    Нет данных
  </Typography>
)

const StyledDataGrid = styled(DataGridPremium)({
  border: 'none',
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader--moving': {
    backgroundColor: 'white',
  },
  '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell:nth-of-type(1)':
    { overflow: 'visible' },
})

const CustomDataGrid = ({ stateKey, stateStorage, slots, slotProps, showDetailOnRowClick = false, getApiRef, ...rest }: CustomDataGridProps) => {

  const { baseTextField, columnsPanel, panel, toolbar, row, ...restSlotProps } = slotProps || {}

  const apiRef = useGridApiRef()
  const [columnsButtonEl, setColumnsButtonEl] = useState(null)
  const [initialStateLocalS, setInitialStateLocalS] = useState<GridInitialState>()

  const storage = window[stateStorage]

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && storage) {
      const currentState = apiRef.current.exportState()
      storage.setItem(stateKey, JSON.stringify(currentState))
    }
  }, [apiRef])

  const handleRowClick = useCallback<GridEventListener<"rowClick">>(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id)
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(
          params.id,
          !rowNode.childrenExpanded
        )
      }
    }, [apiRef]
  )

  useLayoutEffect(() => {
    const stateFromLocalStorage = storage?.getItem(stateKey)
    setInitialStateLocalS(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {}
    )

    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot)

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot)
      saveSnapshot()
    }
  }, [saveSnapshot])

  const expansionState = useRef<{ [key: string]: boolean }>({})
  const isGroupExpanded = useCallback((node: GridGroupNode) => expansionState?.current?.[node.id] ?? false, [expansionState?.current])

  useEffect(() => {
    if (typeof apiRef?.current.subscribeEvent !== 'function') return
    apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
      expansionState.current[node.id] = node.childrenExpanded ?? false
    })
  }, [expansionState, apiRef?.current])

  useEffect(() => {
    getApiRef && getApiRef(apiRef)
  }, [apiRef])

  return (
    initialStateLocalS && (
      <StyledDataGrid
        apiRef={apiRef}
        disableColumnMenu
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          ...rest.initialState,
          ...initialStateLocalS,
        }}
        isGroupExpandedByDefault={isGroupExpanded}
        slots={{
          toolbar: DataGridToolbar,
          noRowsOverlay: noRowsOverlay,
          detailPanelExpandIcon: () => <FontIcon icon='expand_more' />,
          detailPanelCollapseIcon: () => <FontIcon icon='expand_less' />,
          ...slots,
        }}
        slotProps={{
          baseTextField: {
            variant: 'outlined',
            size: 'small',
            ...baseTextField,
          },
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
            getTogglableColumns: (columns) =>
              columns
                .filter((column) => {
                  return (
                    column.field !== '__row_group_by_columns_group__' &&
                    column.field !== '__check__' &&
                    column.field !== '__order__' &&
                    column.field !== 'actions' &&
                    column
                  )
                })
                .map((column) => column.field),
            ...columnsPanel,
          },
          panel: {
            anchorEl: columnsButtonEl,
            placement: 'bottom-end',
            sx: (theme) => ({
              '& .MuiPaper-root': {
                paddingTop: '16px',
                borderRadius: '8px',
                boxShadow: '0px 2px 8px 0px rgba(39, 69, 104, 0.12)',
              },
              '& .MuiDataGrid-columnsPanelRow': {
                height: '32px',
              },
              '& .MuiFormControlLabel-label': {
                ...theme.typography.subtitle1,
              },
            }),
            ...panel,
          },
          toolbar: {
            setColumnsButtonEl,
            ...toolbar,
          },
          ...restSlotProps,
        }}
        onRowClick={showDetailOnRowClick ? handleRowClick : undefined}
        {...rest}
      />
    )
  )
}

export default CustomDataGrid
