import { IconButton, Stack, TextField } from "@mui/material"
import Button from 'components/ui/Button/Button'
import ChainStack from "components/ui/ChainStack/ChainStack"
import FontIcon from "components/ui/FontIcon/FontIcon"
import { useAppDispatch, useAppSelector } from "config/store"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"
import { setLocalSearchValue } from "reducers/localSearch"

interface SearchControlsProps {
	showLocalSearch: boolean
}

const SearchControls = ({ showLocalSearch }: SearchControlsProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useAppDispatch()
	const { value: searchValue, disabled: localSearchDisabled } = useAppSelector(state => state.localSearch)

	const setSearchValue = (value: string) => {
		dispatch(setLocalSearchValue(value))
	}

	const handleGlobalSearch = () => {
		navigate(searchValue ? `/search?text=${searchValue}` : '/search')
	}

	return (
		<Stack spacing='16px' id='search' direction='row' alignItems='flex-end' justifyContent='space-between'>
			{showLocalSearch && (
				<TextField
					id='node-search'
					variant='standard'
					placeholder={t('kbl.labels.searchInCurrFolder')}
					value={searchValue}
					disabled={localSearchDisabled}
					InputProps={{
						startAdornment: <FontIcon icon='search' sx={{ mr: '8px', pb: '3px' }} />,
						endAdornment: searchValue?.length > 0 ? (
							<IconButton size='small' onClick={() => setSearchValue('')}>
								<FontIcon icon='close' />
							</IconButton>
						) : ''
					}}
					onChange={(e) => {
						setSearchValue(e.target.value)
					}}
					sx={{
						width: '200px'
					}}
				/>
			)}
			<ChainStack spacing='16px'>
				<Button variant='contained' size='small' onClick={handleGlobalSearch}
					sx={{
						width: 'max-content',
						color: 'white',
						background: 'rgba(93, 111, 131, 1)',
					}}
				>
					{t('kbl.buttons.globalSearch')}
				</Button>
				<Button size='small' variant='contained' color='error'
					onClick={() => navigate('/rating', { state: { background: location } })}
					sx={{ width: 'max-content' }}
				>
					Оценить работу юристов
				</Button>
			</ChainStack>
		</Stack>
	)
}

export default SearchControls
