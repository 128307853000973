import { FormControl, MenuItem, Select, Stack, TextField } from "@mui/material"
import { ResponsibleType } from "models/responsible.model"
import { Controller, useFormContext } from "react-hook-form"
import { getArrayTypeKeys, getArrayTypeValues } from "../../ResponsibleNodeDialog/utils"
import { formTextAreasName, formTextFieldsFullName, formTextFieldsName } from '../utils'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ResponsibleForm = (props) => {
  const { register, control, setValue, watch } =
    useFormContext<ResponsibleType>();

  const [visibleDepartmentsList, setVisibleDepartmentsList] = useState([]);
  const {
			responsible,
    groupsData,
    currentSubdivision,
    currentDepartment,
    currentColleaguesList
  } = props;
  // группа,  если она изменялась. Значение с сервера - currentSubdivision
  const changedSubdivision = watch('subdivision');
		const changedDepartment = watch('department');

		const { t } = useTranslation();

  useEffect(() => {
				if (!!currentSubdivision && typeof currentSubdivision.id === 'string') 
					setValue('subdivision', changedSubdivision);
  }, [currentSubdivision, setValue, changedSubdivision]);

  useEffect(() => {
    // при обнулении группы, назначаем для department первое значение из списка "без группы"
    if (changedSubdivision === null) {
      setValue('department', groupsData?.orphanDepartments[0]?.id);
    }
    if (!!currentDepartment && typeof currentDepartment.id === 'string') {
      setValue('department', currentDepartment.id);
    }
  }, [
    currentDepartment,
    setValue,
    changedSubdivision,
    groupsData.orphanDepartments,
  ]);

  useEffect(() => {
    if (!!changedSubdivision) {
      const visibleList = groupsData?.subdivisions?.find(
        (el) => el.id === changedSubdivision
      )?.departments;
      setVisibleDepartmentsList(visibleList);
    }
    if ((changedSubdivision === null && !!currentSubdivision)) {
      setVisibleDepartmentsList(groupsData?.orphanDepartments);
    }
    if (!currentSubdivision && !changedSubdivision) 
					setVisibleDepartmentsList(groupsData?.orphanDepartments);
  }, [currentSubdivision, changedSubdivision, groupsData, changedDepartment]);

  const groups = useMemo(() => {
    return groupsData?.subdivisions?.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ));
  }, [groupsData?.subdivisions]);

  const departments = useMemo(() => {
    return visibleDepartmentsList?.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ));
  }, [visibleDepartmentsList]);

		const numbers = useMemo(() => {
      return currentColleaguesList?.map(({ number, name }) => (
        <MenuItem key={number} value={number}>
          {number}. {name}
        </MenuItem>
      ));
    }, [currentColleaguesList]);

  return (
    <FormControl id='responsible-form'>
      <Stack spacing='16px'>
        {getArrayTypeKeys(formTextFieldsFullName).map((el, i) => (
          <TextField
            key={i}
            {...register(
              el,
              el === 'firstName' || el === 'middleName' || el === 'lastName'
                ? { required: true }
                : { required: false }
            )}
            size='medium'
            variant='outlined'
            label={`${getArrayTypeValues(formTextFieldsFullName)[i]} *`}
            fullWidth
          />
        ))}
        {responsible && (
          <Controller
            name='number'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
																autoWidth
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                renderValue={(value) => `Порядковый номер  - ${value}`}
              >
                {numbers || []}
              </Select>
            )}
          />
        )}
        <Controller
          name='subdivision'
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              select
              label={t('kbl.dialog.title.subdivision')}
              value={
                value === undefined || value === null || groups.length === 0
                  ? ''
                  : value
              }
              onChange={(e) => {
                setValue('department', null);
                onChange(e.target.value);
              }}
            >
              {groups || []}
              {!!changedDepartment && (
                <MenuItem value={null}>
                  <em>{t('kbl.dialog.title.withOutSubdivision')}</em>
                </MenuItem>
              )}
            </TextField>
          )}
        />
        <Controller
          name='department'
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              select
              label={t('kbl.dataGrid.department')}
              value={
                value === undefined ||
                value === null ||
                departments?.length === 0
                  ? '0'
                  : value
              }
              onChange={(e) => {
                onChange(e.target.value);
              }}
            >
              {departments || []}
              {!!changedSubdivision && (
                <MenuItem value={null}>
                  <em>{t('kbl.dialog.title.withOutDepartment')}</em>
                </MenuItem>
              )}
            </TextField>
          )}
        />
        {getArrayTypeKeys(formTextFieldsName).map((el, i) => (
          <TextField
            key={i}
            {...register(el, { required: false })}
            size='medium'
            variant='outlined'
            label={`${getArrayTypeValues(formTextFieldsName)[i]}`}
            fullWidth
          />
        ))}
        {getArrayTypeKeys(formTextAreasName).map((el, i) => (
          <TextField
            key={i}
            {...register(el, { required: false })}
            variant='outlined'
            fullWidth
            multiline
            rows={3}
            label={`${getArrayTypeValues(formTextAreasName)[i]}`}
            sx={{
              '& .MuiOutlinedInput-root': { height: '88px' },
            }}
          />
        ))}
      </Stack>
    </FormControl>
  );
};

export default ResponsibleForm
