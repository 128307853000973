import { Stack } from '@mui/material'
import { isShowQuickNodes } from 'config/constants'
import NodeType from 'models/node.model'
import ResponsibleDialog from "modules/Main/Content/ResponsibleReference/ResponsibleDialog"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetSubdivisionsQuery } from 'reducers/api/subdivision.api'
import useConfirm from "../../../../hooks/useConfirm"
import { CurrentColleaguesType, ResponsibleType } from "../../../../models/responsible.model"
import {
  useGetFilteredResponsibleMutation,
  useRemoveResponsibleMutation,
} from "../../../../reducers/api/responsible.api"
import QuickNodes from '../TopPanel/QuickNodes/QuickNodes'
import ResponsibleInfoDialog from './ResponsibleInfoDialog'
import ResponsibleNodeDialog from "./ResponsibleNodeDialog/ResponsibleNodeDialog"
import ResponsibleReferenceTable from "./ResponsibleReferenceTable/ResponsibleReferenceTable"
import { isEditable } from 'utils/checkRight';

const ResponsibleReference = ({ node }: { node: NodeType }) => {
  const { t } = useTranslation()
  const [selectedResponsible, setSelectedResponsible] =
    useState<ResponsibleType>(null)
  const [openResponsibleDialog, setOpenResponsibleDialog] = useState(false)
  const [openResponsibleNodeDialog, setOpenResponsibleNodeDialog] =
    useState(false)
  const [openResponsibleInfoDialog, setOpenResponsibleInfoDialog] =
    useState(false)
  const [groupingExpansion, setGroupingExpansion] = useState(0)
  const [expansionsInfo, setExpansionsInfo] = useState(
    t('kbl.dataGrid.buttons.expandRows')
  )
  const [queryOptions, setQueryOptions] = useState([])
  
  const confirmArchive = useConfirm()
		

  const [removeResponsible] = useRemoveResponsibleMutation()
  const { data: groupsData } = useGetSubdivisionsQuery()
  const [getFilteredResponsible, { data: responsiblesData }] =
    useGetFilteredResponsibleMutation()

  useEffect(() => {
    getFilteredResponsible(queryOptions)
  }, [queryOptions])

  const changeExpansions = () => {
    setGroupingExpansion((pre) => (pre === -1 ? 0 : -1))
    setExpansionsInfo((pre) =>
      typeof pre === 'string' && pre === t('kbl.dataGrid.buttons.collapseRows')
        ? t('kbl.dataGrid.buttons.expandRows')
        : t('kbl.dataGrid.buttons.collapseRows')
    )
  }

  const handleAddResponsible = () => {
    setOpenResponsibleDialog(true)
  }

  const closeResponsibleDialog = () => {
    setOpenResponsibleDialog(false)
    setSelectedResponsible(null)
  }

  const closeResponsibleInfoDialog = () => {
    setOpenResponsibleInfoDialog(false)
    setSelectedResponsible(null)
  }

  const closeResponsibleNodeDialog = () => {
    setOpenResponsibleNodeDialog(false)
    setSelectedResponsible(null)
  }

  const showResponsibleInfo = useCallback((data: ResponsibleType) => {
    setSelectedResponsible(data)
    setOpenResponsibleInfoDialog(true)
  }, [])

  const showResponsibleNode = useCallback((data: ResponsibleType) => {
    setSelectedResponsible(data)
    setOpenResponsibleNodeDialog(true)
  }, [])

  const updateResponsible = useCallback((data: ResponsibleType) => {
    setSelectedResponsible(data)
    setOpenResponsibleDialog(true)
  }, [])

  const deleteResponsible = useCallback((data: ResponsibleType) => {
    confirmArchive({
      title: t('kbl.dialog.title.general.confirm'),
      message: t('kbl.dialog.message.deleteResponsible'),
      acceptColor: 'error',
      onAccept: async () => {
        await removeResponsible({ id: data.id })
        getFilteredResponsible(queryOptions)
      },
    })
  }, [])

  const handleSuccess = () => {
    getFilteredResponsible(queryOptions)
  }

  const currentColleaguesList = (): CurrentColleaguesType[] => {
    const currSubD = selectedResponsible?.subdivision
      ? selectedResponsible?.subdivision?.id
      : null
    const currDept = selectedResponsible?.department
      ? selectedResponsible?.department?.id
      : null
    const list = responsiblesData?.content
      ?.filter(
        (el) =>
          (el.subdivision?.id === currSubD || el.subdivision === currSubD) &&
          (el.department?.id === currDept || el.department === currDept)
      )
      .map((el) => ({ number: el.number, name: el.fullName }))
      .sort((a, b) => (a.number ?? 0) - (b.number ?? 0))
    return list
  }

  return (
    <Stack overflow='auto'>
      {isShowQuickNodes && <QuickNodes parent={node} />}

      <ResponsibleReferenceTable
        data={responsiblesData?.content}
        onResponsibleClick={showResponsibleInfo}
        handleAddResponsible={handleAddResponsible}
        showResponsibleNode={showResponsibleNode}
        updateResponsible={updateResponsible}
        deleteResponsible={deleteResponsible}
        setQueryOptions={setQueryOptions}
        groupingExpansion={groupingExpansion}
        expansionsInfo={expansionsInfo}
        changeExpansions={changeExpansions}
        onSuccess={handleSuccess}
        isEditable={isEditable(node)}
      />

      {openResponsibleDialog && (
        <ResponsibleDialog
          currentColleaguesList={currentColleaguesList()}
          responsible={selectedResponsible}
          onClose={closeResponsibleDialog}
          onSuccess={handleSuccess}
          groupsData={groupsData}
        />
      )}

      {openResponsibleInfoDialog && (
        <ResponsibleInfoDialog
          responsible={selectedResponsible}
          onClose={closeResponsibleInfoDialog}
        />
      )}

      {openResponsibleNodeDialog && (
        <ResponsibleNodeDialog
          responsible={selectedResponsible}
          onClose={closeResponsibleNodeDialog}
        />
      )}
    </Stack>
  );
}

export default ResponsibleReference
