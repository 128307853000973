import { Input, InputProps } from "@mui/material"
import useActions from "hooks/useActions"
import NodeType from "models/node.model"
import { useEffect, useRef, useState } from "react"

interface NodeRenamingProps extends InputProps {
  node: NodeType
  renaming: NodeType
  onConfirm: (newName?: string) => Promise<void>
}

const NodeRenaming = (props: NodeRenamingProps) => {

  const { node, renaming, onConfirm, ...rest } = props

  const [newName, setNewName] = useState('')
  const { completeRenameNode } = useActions(node)

  const ref = useRef<HTMLInputElement>(null)

  const cancelRenameNode = (e: React.FocusEvent | React.KeyboardEvent) => {
    e.stopPropagation()
    e.preventDefault()
    completeRenameNode()
  }

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value)
  }

  const handleConfirm = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    onConfirm(newName)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleConfirm(e)
    }
    if (e.key === 'Escape') {
      cancelRenameNode(e)
    }
  }

  useEffect(() => {
    if (renaming?.id === node.id) {
      setNewName(renaming.name as string)
    }
  }, [node.id, renaming?.id])

  useEffect(() => {
    if (ref)
      ref.current.focus()
  }, [ref])

  return (
    <Input
      inputRef={ref}
      disableUnderline
      defaultValue={renaming?.name}
      sx={{
        '& .MuiInput-input': (theme) => ({
          width: '100px',
          minHeight: '26px',
          maxHeight: '26px',
          padding: '4px',
          lineHeight: '1rem',
          borderRadius: '3px',
          border: `1px solid ${theme.palette.primary.main}`,
        }),
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onFocus={(e) => {
        return e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length
        )
      }}
      onChange={handleEditChange}
      onKeyDown={handleKeyDown}
      onBlur={handleConfirm}
      {...rest}
    />
  )
}

export default NodeRenaming
