import { Checkbox } from "@mui/material"
import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import FontIcon from "components/ui/FontIcon"
import renderOption from "components/utils/render-option"
import _ from "lodash"
import { ResponsibleType } from "models/responsible.model"
import { useEffect, useMemo } from "react"
import { useGetFilteredResponsibleMutation } from "reducers/api/responsible.api"
import { useGetSubdivisionsQuery } from "reducers/api/subdivision.api"

const ResponsibleAutoComplete = ({ multiple, departments, ...rest }: AutoCompleteProps<ResponsibleType> & { departments?: boolean }) => {

  const [getFilteredResponsible, { data: responsibleData, isLoading: isResponsibleLoading }] = useGetFilteredResponsibleMutation()

  const { data: subdivisions } = useGetSubdivisionsQuery()

  const allDepartments = useMemo(() => {
    const all = []
    subdivisions?.subdivisions?.forEach(s => {
      all.push(s.name)
      s.departments?.forEach(d => { all.push(d.name) })
    })
    subdivisions?.orphanDepartments?.forEach(d => all.push(d.name))
    return all
  }, [subdivisions])

  useEffect(() => {
    getFilteredResponsible([])
  }, [])

  return (
    <AutoComplete<ResponsibleType | string>
      fullWidth
      clientSide
      data={departments ? _.sortBy(_.uniq(allDepartments)) : responsibleData?.content}
      loading={isResponsibleLoading}
      optionLabel={departments ? undefined : 'fullName'}
      multiple={multiple}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && (
            <Checkbox
              icon={<FontIcon icon='check_box_outline_blank' sx={{ color: 'primary.text03' }} />}
              checkedIcon={<FontIcon icon='check_box' sx={{ color: 'primary.main' }} />}
              checked={selected}
              sx={{
                padding: 0
              }}
            />
          )}
          {renderOption(departments ? { label: option as string } : { label: `${(option as ResponsibleType)?.fullName}`, description: (option as ResponsibleType)?.department?.name })}
        </li>
      )}
      getAdornment={!departments ? (option: ResponsibleType) => option?.department?.name : undefined}
      {...rest}
    />
  )
}

export default ResponsibleAutoComplete
