import { Stack } from "@mui/material"
import axios, { AxiosHeaders, AxiosResponse } from "axios"
import Button from "components/ui/Button/Button"
import { ACCESS_TOKEN, API_URL } from "config/constants"
import { toast } from "react-toastify"
import { useGetMeQuery } from "reducers/api/employee.api"
import { openFile } from "utils/openfile"
import { RATING_STEP } from "./Rating"

interface RatingStartProps {
  setStep: (step: RATING_STEP) => void
}

const downloadReport = async () => {

  const url = `${API_URL}/feedback/answer`

  try {
    let response: AxiosResponse
    response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`,
      },
    })
    let serverFilename = ''
    let contentType = ''
    if (response?.headers instanceof AxiosHeaders) {
      contentType = response.headers.get("content-type") as string
      const contentDisposition = response.headers.get("content-disposition") as string
      const rawServerFilename = contentDisposition?.split('filename=')[1]
      serverFilename = rawServerFilename?.replace(/["']+/g, '') ?? 'rating-report.xlsx'
      console.log(contentDisposition, rawServerFilename)
    }
    openFile(contentType, response.data, serverFilename)
  } catch (e) {
    toast.error('Не удалось скачать отчет')
  }
}

const RatingStart = ({ setStep }: RatingStartProps) => {

  const { data: meData } = useGetMeQuery()

  return (
    <Stack spacing='10px' height='100%' justifyContent='center'>
      <Button fullWidth variant='contained' onClick={() => setStep(RATING_STEP.EMPLOYEE)}>
        Оценить работу сотрудника
      </Button>
      <Button fullWidth variant='contained' onClick={() => setStep(RATING_STEP.DEPARTMENT)}>
        Оценить работу подразделения ДП
      </Button>
      {meData?.role.recordType === 'SUPPORT_ADMIN' && (
        <Button fullWidth variant='outlined' onClick={downloadReport}>
          Скачать отчет
        </Button>
      )}
    </Stack>
  )
}

export default RatingStart
