import { ResponsibleType } from "models/responsible.model"

const buildColleagueFinder = (employees: ResponsibleType[]) => {
  const groups = {}
  const employeeToGroupKey = {}

  for (const employee of employees) {
    const employeeId = employee.id
    let groupKey: string

    // Определяем ключ группы по приоритету: department > subdivision
    if (employee.department) {
      groupKey = `department-${employee.department.id}`
    } else if (employee.subdivision) {
      groupKey = `subdivision-${employee.subdivision.id}`
    } else {
      continue // Сотрудники без department и subdivision не входят ни в одну группу
    }

    // Инициализируем группу, если её ещё нет
    if (!groups[groupKey]) {
      groups[groupKey] = []
    }

    // Добавляем сотрудника в группу и сохраняем связь id -> groupKey
    groups[groupKey].push(employee)
    employeeToGroupKey[employeeId] = groupKey
  }

  return {
    getColleagues: (id: string) => {
      const groupKey = employeeToGroupKey[id]
      return groups[groupKey]?.sort((a: ResponsibleType, b: ResponsibleType) => {
        if (a.number > b.number) return 1
        if (a.number < b.number) return -1
        return 0
      }) ?? []
    },

    getByDepartmentId: (departmentId: string) => {
      const key = `department-${departmentId}`
      return groups[key] ?? []
    },

    getBySubdivisionId: (subdivisionId: string) => {
      const key = `subdivision-${subdivisionId}`
      return groups[key] ?? []
    }
  }
}

export default buildColleagueFinder
