import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ContextState {
  currentContextId: string;
}

const initialState: ContextState = {
  currentContextId: undefined,
};

const context = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContextId: (state: ContextState, { payload }: PayloadAction<{ currentContextId: string } | undefined>) => {
      state.currentContextId = payload?.currentContextId
    },
  }
})

export const {
  setContextId,
} = context.actions

export default context
